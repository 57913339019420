
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";

import EquipmentCategoryAutocomplete from "@/components/General/Autocompletes/Equipments/EquipmentCategoryAutocomplete.vue";

import { PROVIDER, PROVIDERS as P } from "@/config/literals";
import { IEquipmentComponentProvider } from "@/providers";

import { EquipmentCategory } from "@/domain";
import { EquipmentFilter } from "@/domain/ui";

@Component({
  components: {
    EquipmentCategoryAutocomplete,
  },
  data: () => {
    return {
      EquipmentCategory,
      EquipmentFilter,
    };
  },
})
export default class EquipmentsContent extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  isUtCategory!: boolean;

  get equipmentComponentProvider() {
    return this.container.resolve<IEquipmentComponentProvider>(
      P.EQUIPMENT_COMPONENT_PROVIDER
    );
  }

  get component() {
    return this.equipmentComponentProvider.get(this.selectedCategory);
  }

  selectedCategory: EquipmentCategory = EquipmentCategory.unknown;
}
