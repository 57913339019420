
import { Component, Prop, Vue } from "vue-property-decorator";
import _ from "lodash";

import { EquipmentCategory } from "@/domain";
import { EquipmentFilter } from "@/domain/ui";

@Component({
  data: () => {
    return {
      EquipmentCategory,
      EquipmentFilter,
    };
  },
})
export default class EquipmentCategoryAutocomplete extends Vue {
  @Prop({ required: false, default: () => EquipmentFilter.includeAll })
  filterCategory!: EquipmentFilter;

  @Prop({ required: true, default: () => EquipmentCategory.unknown })
  value!: EquipmentCategory;

  get utCategories() {
    return [
      {
        value: EquipmentCategory.utProbe,
        text: "Traducteurs",
      },
      {
        value: EquipmentCategory.utBoard,
        text: "Postes",
      },
      {
        value: EquipmentCategory.utGel,
        text: "Couplants",
      },
      {
        value: EquipmentCategory.calibrationBlock,
        text: "Étalons",
      },
    ];
  }

  get otherCategories() {
    return [
      {
        value: EquipmentCategory.degreaser,
        text: "Nettoyants",
      },
      {
        value: EquipmentCategory.developer,
        text: "Révélateurs",
      },
      {
        value: EquipmentCategory.hygrometer,
        text: "Hygromètres",
      },
      {
        value: EquipmentCategory.luxmeter,
        text: "Luxmètres",
      },
      {
        value: EquipmentCategory.penetrant,
        text: "Pénétrants",
      },
      {
        value: EquipmentCategory.roughometer,
        text: "Rugosimètres",
      },
      {
        value: EquipmentCategory.thermometer,
        text: "Thermomètres",
      },
    ];
  }

  get allCategories() {
    return _.union(this.utCategories, this.otherCategories);
  }

  get categoriesFiltered() {
    switch (this.filterCategory) {
      case EquipmentFilter.includeUt: {
        return this.utCategories;
      }
      case EquipmentFilter.includeOthers: {
        return this.otherCategories;
      }
      default: {
        return this.allCategories;
      }
    }
  }
}
