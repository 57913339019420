
import { Component, Vue } from "vue-property-decorator";

import EquipmentsContent from "@/components/EquipmentsContent.vue";

@Component({
  components: {
    EquipmentsContent,
  },
})
export default class Equipments extends Vue {}
